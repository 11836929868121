var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"all-devices "},[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2 mb-0"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6","sm":"12","offset-sm":"0","offset-md":"6","offset-lg":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.pageChanged(1)}},model:{value:(_vm.searchDevices),callback:function ($$v) {_vm.searchDevices=$$v},expression:"searchDevices"}}),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.pageChanged(1)}}},[_vm._v(" Search ")])],1)])],1)],1),(_vm.spinner)?_c('b-skeleton-table',{attrs:{"rows":_vm.paginationObject.limit,"columns":3,"table-props":{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }}}):_c('div',{staticClass:"heading-elements ml-0"},[_c('b-table',{staticClass:"float: right",attrs:{"id":"devices-table","per-page":_vm.paginationObject.limit,"items":_vm.allDevices,"responsive":"","fields":_vm.tableColumns,"show-empty":""},scopedSlots:_vm._u([{key:"cell(Devices)",fn:function(data){return [_c('b-link',{attrs:{"to":{
                name:'single-device-admin',
                params:{
                  deviceId: data.item.id.toString(),
                  userId: _vm.$route.params.userId,
                  deviceName: data.item.name,
                  venueId: _vm.$route.params.venueId
                }
              }}},[_c('span',{staticClass:"d-block text-nowrap devices"},[_vm._v(" "+_vm._s(data.item.name)+" ")])])]}},{key:"cell(Group)",fn:function(data){return _vm._l((data.item.device_has_group),function(device_has_group){return _c('span',{key:device_has_group.id},[(_vm.spinners[data.item.id + '' + device_has_group.device_groups.id])?_c('div',{staticClass:"spinner"},[_c('b-spinner',{attrs:{"variant":"primary","small":""}})],1):_c('b-badge',{staticClass:"group-badge",attrs:{"href":"#","variant":"light"}},[_vm._v(" "+_vm._s(device_has_group.device_groups.name)+" ")])],1)})}},{key:"cell(Action)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"id":("device-" + (data.item.mac_address) + "-delete-icon"),"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.deleteDevice(data.item.mac_address)}}})],1)]}},{key:"cell(BANNER-MODE)",fn:function(data){return [_c('span',{staticClass:"float-right"},[(_vm.childSpinner[data.item.mac_address])?_c('div',{staticClass:"spinner text-center",staticStyle:{"width":"75px"}},[_c('b-spinner',{attrs:{"variant":"primary","small":""}})],1):_c('vs-switch',{staticClass:"switchs",style:(("background-color: " + (data.item.is_banner_ad_mode_enabled ? '#00E265' : '#FF0000') + ";color:white;")),attrs:{"disabled":"","value":data.item.is_banner_ad_mode_enabled}},[_c('span',{staticStyle:{"font-weight":"bold"},attrs:{"slot":"on"},slot:"on"},[_vm._v("Disable")]),_c('span',{staticStyle:{"font-weight":"bold"},attrs:{"slot":"off"},slot:"off"},[_vm._v("Enable")])])],1)]}}])})],1)],1),_c('div',[_c('CustomPagination',{attrs:{"total":_vm.paginationObject.total,"per-page":_vm.paginationObject.limit,"page":_vm.paginationObject.offset},on:{"perPageChanged":_vm.perPageChanged,"pageChanged":_vm.pageChanged}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }